import { __decorate } from "tslib";
import AppBtn from "@/components/shared/ui/AppBtn.vue";
import AppCheckbox from "@/components/shared/ui/AppCheckbox.vue";
import AppCreateSidenav from "@/components/shared/ui/AppCreateSidenav.vue";
import AppSelect from "@/components/shared/ui/AppSelect.vue";
import AppTextField from "@/components/shared/ui/AppTextField.vue";
import { clone } from "@/helpers/object";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { v4 as uuid } from "uuid";
import { Component, Emit, Prop, ProvideReactive, VModel, Vue, } from "vue-property-decorator";
let IndicatorChartCreate = class IndicatorChartCreate extends Vue {
    constructor() {
        super(...arguments);
        this.isSubmitting = false;
        this.fields = null;
        this.showIdError = false;
    }
    get title() {
        return this.editedItem
            ? `Modifier ${this.editedItem.name}`
            : "Nouveau graphique";
    }
    get isFormValid() {
        return !!this.fields?.name && !!this.fields?.labels?.length;
    }
    get showDelete() {
        return !!this.editedItem;
    }
    get indicatorItems() {
        return indicatorReferencesModule.indicatorReferences?.map((i) => ({
            label: i.name,
            value: i.id,
        }));
    }
    get showTotalItems() {
        return [
            { label: "Oui", value: true },
            { label: "Non", value: false },
        ];
    }
    get showGroupAverageItems() {
        return [
            { label: "Ne pas afficher", value: false },
            { label: "Total", value: "total" },
        ].concat(this.fields.labels?.map((l) => ({
            label: l.name,
            value: l.id,
        })) || []);
    }
    created() {
        this.prefillForm();
    }
    saveItem() {
        if (this.isSubmitting || !this.isFormValid)
            return;
        const data = {
            ...this.fields,
            id: this.fields.id || uuid(),
        };
        data.labels = data.labels.filter((c) => !!c.name);
        indicatorChartReferencesModule
            .upsertIndicatorChart(data)
            .then(() => {
            this.$emit("saved", true);
            this.showSidenav = false;
        })
            .finally(() => (this.isSubmitting = false));
    }
    prefillForm() {
        this.fields = {
            id: "",
            name: "",
            labels: [],
            showTotal: false,
            showGroupAverage: false,
            ...clone(this.editedItem || {}),
        };
    }
    deleteItem() {
        this.showSidenav = false;
        return this.editedItem;
    }
    addChart() {
        this.fields.labels.push({ id: uuid(), name: "" });
    }
    removeChart(labelId) {
        this.fields.labels = this.fields.labels.filter((l) => l.id !== labelId);
        this.emitChangeEvent();
    }
    upChart(labelId) {
        const index = this.fields.labels.findIndex((l) => l.id === labelId);
        const item = this.fields.labels[index];
        if (index > 0) {
            this.removeChart(labelId);
            this.fields.labels.splice(index - 1, 0, item);
            this.emitChangeEvent();
        }
    }
    downChart(labelId) {
        const index = this.fields.labels.findIndex((l) => l.id === labelId);
        const item = this.fields.labels[index];
        if (index < this.fields.labels.length - 1) {
            this.removeChart(labelId);
            this.fields.labels.splice(index + 1, 0, item);
            this.emitChangeEvent();
        }
    }
    emitChangeEvent() {
        const event = new Event("change", {
            bubbles: true,
            cancelable: true,
        });
        console.log(1, this.$refs);
        this.$refs.labels?.dispatchEvent(event);
    }
};
__decorate([
    VModel({ default: false })
], IndicatorChartCreate.prototype, "showSidenav", void 0);
__decorate([
    Prop()
], IndicatorChartCreate.prototype, "editedItem", void 0);
__decorate([
    ProvideReactive()
], IndicatorChartCreate.prototype, "title", null);
__decorate([
    ProvideReactive()
], IndicatorChartCreate.prototype, "isFormValid", null);
__decorate([
    ProvideReactive()
], IndicatorChartCreate.prototype, "showDelete", null);
__decorate([
    ProvideReactive()
], IndicatorChartCreate.prototype, "isSubmitting", void 0);
__decorate([
    Emit("deleteItem")
], IndicatorChartCreate.prototype, "deleteItem", null);
IndicatorChartCreate = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextField,
            AppSelect,
            AppCreateSidenav,
            AppCheckbox,
        },
    })
], IndicatorChartCreate);
export default IndicatorChartCreate;
