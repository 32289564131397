import { __decorate } from "tslib";
import { NoCache } from "@/helpers/NoCache";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppModal from "../shared/ui/AppModal.vue";
import IndicatorChartReferenceCreate from "./indicatorChartReferences/IndicatorChartReferenceCreate.vue";
let IndicatorChartChartList = class IndicatorChartChartList extends Vue {
    constructor() {
        super(...arguments);
        this.showDeleteConfirmModal = false;
        this.showIndicatorChartCreate = false;
        this.indicatorChartToDelete = null;
        this.editedIndicatorChart = null;
        this.showIssues = true;
    }
    get indicatorChartReferences() {
        return indicatorChartReferencesModule.indicatorChartReferences;
    }
    mounted() {
        indicatorChartReferencesModule.loadIndicatorCharts();
        this.$events.$emit("menu-change", {
            menu: "Paramètres",
            subMenu: "Indicateurs",
        });
    }
    editIndicatorChart(indicatorChart) {
        this.editedIndicatorChart = indicatorChart;
        this.showIndicatorChartCreate = true;
    }
    deleteIndicatorChart(indicatorChart) {
        this.indicatorChartToDelete = indicatorChart;
        this.showDeleteConfirmModal = true;
    }
    onDeleteConfirm(confirmed) {
        if (confirmed)
            indicatorChartReferencesModule.deleteIndicatorChart(this.indicatorChartToDelete.id);
        this.indicatorChartToDelete = null;
        this.showDeleteConfirmModal = false;
    }
    onShowIndicatorChartCreate(showIndicatorChartCreate) {
        if (!showIndicatorChartCreate)
            this.editedIndicatorChart = null;
    }
    getIndicatorName(indicatorId) {
        return indicatorReferencesModule.indicatorReferences?.find((i) => i.id === indicatorId).name;
    }
};
__decorate([
    NoCache()
], IndicatorChartChartList.prototype, "indicatorChartReferences", null);
__decorate([
    Watch("showIndicatorChartCreate")
], IndicatorChartChartList.prototype, "onShowIndicatorChartCreate", null);
IndicatorChartChartList = __decorate([
    Component({
        components: {
            IndicatorChartReferenceCreate,
            AppModal,
        },
    })
], IndicatorChartChartList);
export default IndicatorChartChartList;
