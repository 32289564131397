import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let AppCheckbox = class AppCheckbox extends Vue {
    get dataTestName() {
        return "appCheckbox-" + this.name;
    }
    onInput(e) {
        e.preventDefault();
        const checked = e.target.checked;
        this.$emit("input", checked);
        this.$emit("change", checked);
    }
    toggleValue(e) {
        e.preventDefault();
        const checked = e.target.checked;
        this.$emit("input", !checked);
        this.$emit("change", !checked);
    }
};
__decorate([
    Prop({ required: true })
], AppCheckbox.prototype, "name", void 0);
__decorate([
    Prop({ default: "" })
], AppCheckbox.prototype, "label", void 0);
__decorate([
    Prop({ default: null })
], AppCheckbox.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], AppCheckbox.prototype, "required", void 0);
__decorate([
    Prop({ default: false })
], AppCheckbox.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false })
], AppCheckbox.prototype, "readonly", void 0);
__decorate([
    Prop({ default: null })
], AppCheckbox.prototype, "tabindex", void 0);
AppCheckbox = __decorate([
    Component
], AppCheckbox);
export default AppCheckbox;
